import { Link, Message } from 'components'
import style from './Sidebar.module.css'
import Logo from 'assets/svg/Logo'
import { MdDownload, MdPeopleAlt } from 'react-icons/md'

const Sidebar = ({ open, onToggle, pageId }) => {
  const handleClick =
    (href) =>
    ({ navigate }) => {
      navigate(href)
      if (window.innerWidth < 576) onToggle()
    }
  return (
    <div className={style['sidebar__wrap']} style={{ width: open ? 250 : 0 }}>
      <div className={style['sidebar']}>
        <Brand />
        <NavLink
          icon={<MdPeopleAlt />}
          label="user.title.list"
          onClick={handleClick('user')}
          active={['user-list', 'user-page'].includes(pageId)}
        />
        <NavLink
          icon={<MdDownload />}
          label="user.title.import"
          onClick={handleClick('user/memo/import')}
          active={['userMemo-page'].includes(pageId)}
        />
        {/* <NavGroup
          icon={<GoPackage />}
          label="sidebar.product"
          active={['category-list', 'product-list', 'product-page'].includes(
            pageId,
          )}
        >
          <NavLink
            label="category.title.list"
            onClick={handleClick('category')}
            active={['category-list'].includes(pageId)}
          />
          <NavLink
            label="product.title.list"
            onClick={handleClick('product')}
            active={['product-list', 'product-page'].includes(pageId)}
          />
        </NavGroup>
        <NavGroup
          icon={<FaClipboardList />}
          label="sidebar.stock"
          active={[
            'inventory-list',
            'buy-list',
            'buy-page',
            'adjust-list',
            'adjust-page',
            'process-list',
            'process-page',
          ].includes(pageId)}
        >
          <NavLink
            label="inventory.title.list"
            onClick={handleClick('inventory')}
            active={['inventory-list'].includes(pageId)}
          />
          <NavLink
            label="buy.title.list"
            onClick={handleClick('buy')}
            active={['buy-list', 'buy-page'].includes(pageId)}
          />
          <NavLink
            label="adjust.title.list"
            onClick={handleClick('adjust')}
            active={['adjust-list', 'adjust-page'].includes(pageId)}
          />
          <NavLink
            label="process.title.list"
            onClick={handleClick('process')}
            active={['process-list', 'process-page'].includes(pageId)}
          />
        </NavGroup>
        <NavGroup
          icon={<FaGlobe />}
          label="sidebar.website"
          active={[
            'shelf-list',
            'shelf-page',
            'merchant-page',
            'promo-list',
          ].includes(pageId)}
        >
          <NavLink
            label="merchant.title.edit"
            onClick={handleClick('merchant/edit')}
            active={['merchant-page'].includes(pageId)}
          />
          <NavLink
            label="shelf.title.list"
            onClick={handleClick('shelf')}
            active={['shelf-list', 'shelf-page'].includes(pageId)}
          />
          <NavLink
            label="promo.title.list"
            onClick={handleClick('promo')}
            active={['promo-list'].includes(pageId)}
          />
        </NavGroup>
        <NavGroup
          icon={<MdSettings />}
          label="sidebar.setting"
          active={['warehouse-list'].includes(pageId)}
        >
          <NavLink
            label="warehouse.title.list"
            onClick={handleClick('warehouse')}
            active={['warehouse-list'].includes(pageId)}
          />
          <NavLink
            label="staff.title.list"
            onClick={handleClick('staff')}
            active={['staff-list'].includes(pageId)}
          />
        </NavGroup> */}
      </div>
    </div>
  )
}

const Brand = () => (
  <div className={style['sidebar__logo']}>
    <div className={style['sidebar__logo__icon']}>
      <Logo color="#fff" />
    </div>
    <Message className={style['sidebar__logo__text']} id="app.name" />
  </div>
)

// const NavGroup = ({ active, icon, label, children }) => {
//   const [open, setOpen] = useState(active)

//   useEffect(() => {
//     setOpen(active)
//   }, [active])

//   return (
//     <>
//       <Link
//         className={{
//           link: style['sidebar__group'],
//           'link--active': active && style['sidebar__group--active'],
//           'link__icon--left': style['sidebar__group__icon--left'],
//         }}
//         leftIcon={icon}
//         rightIcon={<IoMdArrowDropdown />}
//         label={label}
//         onClick={() => setOpen(!open)}
//       />
//       <Collapsible className={style['sidebar__group__content']} open={open}>
//         {children}
//       </Collapsible>
//     </>
//   )
// }

const NavLink = ({ icon, label, onClick, active }) => (
  <Link
    className={{
      link: style['sidebar__link'],
      'link--active': active && style['sidebar__link--active'],
      'link__icon--left': style['sidebar__link__icon--left'],
    }}
    leftIcon={icon}
    label={label}
    onClick={onClick}
  />
)

export default Sidebar
