import { Suspense, lazy } from 'react'
import { ALERT_ADD } from 'constants/actionType'
import { formatDate, formatTimestamp } from 'utilities/date'
import {
  Link,
  Definition,
  Table,
  TextInput,
  DateInput,
  Select,
  TextArea,
  PhoneInput,
  Switch,
  NumberInput,
} from 'components'
import { RiCheckboxLine, RiCheckboxBlankLine } from 'react-icons/ri'

const Address = lazy(() => import('components/Address/Address'))

export function initializeState(value) {
  return {
    ...value,
    __error__: Object.keys(value).reduce((result, key) => {
      result[key] = ''
      return result
    }, {}),
  }
}

export function handleKeyPress(pattern) {
  return (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    if (!pattern.test(keyValue)) {
      event.preventDefault()
    }
  }
}

export function handleTextChange(id, state, setState, validation, callback) {
  return async (value) => {
    const { message } = validateField(value, validation[id], state)
    let callbackVal = {}

    if (callback) {
      callbackVal = await callback(value)
    }

    setState({
      ...state,
      ...callbackVal,
      [id]: value,
      __error__: { ...state.__error__, [id]: message },
    })
  }
}

export function handleSelectChange(id, state, setState, validation, callback) {
  return async (item) => {
    const value = item ? (Array.isArray(item) ? item : item.value) : null
    const { message } = validateField(value, validation[id], state)
    let callbackVal = {}

    if (callback) {
      callbackVal = await callback(item)
    }

    setState({
      ...state,
      ...callbackVal,
      [id]: item,
      __error__: { ...state.__error__, [id]: message },
    })
  }
}

export function getSelectOption(
  options,
  value,
  valueKey = 'value',
  labelKey = 'label',
  nullable = true,
) {
  if (!options || options.length === 0) return {}

  if (!value) {
    if (nullable) return {}
    const option = options[0]
    return { value: option[valueKey], label: option[labelKey] }
  }

  const option = options.find((item) => `${item[valueKey]}` === `${value}`)
  if (!option) return {}
  return { value: option[valueKey], label: option[labelKey] }
}

export function getMultiSelectOption(
  options,
  value,
  valueKey = 'value',
  labelKey = 'label',
) {
  if (!options || options.length === 0) return []
  if (!value || value.length === 0) return []

  return value.reduce((result, itemVal) => {
    const option = options.find((item) => item[valueKey] === `${itemVal}`)
    if (!option) return result
    result.push({ value: option[valueKey], label: option[labelKey] })
    return result
  }, [])
}

export function getSelectOptions(options, valueKey = 'id', labelKey = 'name') {
  if (!options) return []
  return options.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
  }))
}

export function getDate(date) {
  return formatDate(date ? new Date(date) : new Date())
}

export function showDate(date) {
  if (!date) return null
  return formatDate(new Date(date))
}

export function showTimestamp(date) {
  if (!date) return null
  return formatTimestamp(new Date(date))
}

export function showAddress(value) {
  if (!value) return ''
  if (typeof value === 'string') return value

  const { zipcode, city, district, street } = value
  let result = ''
  if (zipcode) result += `${zipcode} `
  if (city) result += city
  if (district) result += district
  if (street) result += street
  // if (result.trim() !== '' && hasLift !== undefined && hasLift !== null) {
  //   const msg = message({ id: `elevator.${hasLift}` })
  //   result += ` [${msg}]`
  // }

  return result
}

export function parseAddress(cityMap, cities, districtMap, districts, address) {
  address = beforeParseAddress(address)
  let [zipcode, rest1] = extractZipcode(address)
  const [city, rest2] = extractStr(cities, rest1)
  const [district, street] = extractStr(districts, rest2)

  if (!zipcode && district) {
    zipcode = districtMap[`${city}_${district}`]?.zipcode
  }
  if (!zipcode && city) zipcode = cityMap[city]?.zipcode

  return { zipcode, city, district, street, hasLift: false }
}

function beforeParseAddress(address) {
  if (!address) return ''
  return `${address}`.replace('台', '臺')
}

function extractZipcode(address) {
  let [, zipcode, rest] = address.match(/(^\d*)(.*)/)
  if (rest) rest = rest.trim()
  return [zipcode, rest]
}

function extractStr(options, value) {
  let matched = ''
  let rest = value

  for (const item of options) {
    const idx = value.indexOf(item)
    if (idx !== -1) {
      matched = item
      rest = value.substr(idx + item.length)
      break
    }
  }

  return [matched, rest]
}

export function handleTagChange(id, state, setState) {
  return (items) => {
    setState({
      ...state,
      [id]: items,
    })
  }
}

export function validateForm({
  session,
  state,
  setState,
  validation,
  crossValidation = [],
}) {
  const error = {}

  for (const [field, rules] of Object.entries(validation)) {
    const { hasError, message } = validateField(state[field], rules, state)

    if (hasError) {
      console.error(field, message)
      error[field] = message
    }
  }

  if (Object.keys(error).length > 0) {
    setState({
      ...state,
      __error__: error,
    })
    return false
  }

  for (const item of crossValidation) {
    const { fields, type, message } = item
    const hasError = validateFields(fields, type, state)

    if (hasError) {
      session.dispatch({ type: ALERT_ADD, item: { type: 'error', message } })
      return false
    }
  }

  return true
}

export function validateRows(rows, validation) {
  return rows.reduce((result, row) => {
    const { ticketNo } = row
    const [ok, message] = validateRow(row, validation)
    if (ok) return result

    result.push({ ticketNo, message })
    return result
  }, [])
}

function validateRow(row, validation) {
  for (const [field, rules] of Object.entries(validation)) {
    const { hasError, message } = validateField(row[field], rules, row)

    if (hasError) {
      console.error(field, message)
      return [false, message]
    }
  }

  return [true]
}

export function validateField(value, rules, state) {
  let hasError = false
  const message = ''

  if (!rules) {
    return { hasError, message: '' }
  }

  for (const validation of rules) {
    switch (validation.type) {
      case 'required':
        hasError = isEmpty(value)
        break
      case 'min':
        hasError = lessThanMin(value, validation.val)
        break
      case 'minLength':
        hasError = lessThanMinLength(value, validation.val)
        break
      case 'maxLength':
        hasError = moreThanMaxLength(value, validation.val)
        break
      case 'fieldEqual':
        hasError = notEqual(value, state[validation.name])
        break
      case 'address':
        hasError = invalidAddress(value, state[validation.name])
        break
      case 'func':
        hasError = validation.func(state)
        break
      default:
        hasError = true
        console.error('Validation rule not supported', validation.type)
    }

    if (hasError) {
      const errMsg = validation.message || `error.${validation.type}`
      return { hasError, message: errMsg }
    }
  }

  return { hasError, message }
}

export function validateFields(fields, type, state) {
  let hasError = false

  switch (type) {
    case 'required':
      hasError = !fields.some((item) => !isEmpty(state[item]))
      break
    default:
      hasError = true
      console.error('Validation rule not supported', type)
  }

  return hasError
}

function isEmpty(value) {
  if (value === undefined || value === null || value === '') {
    return true
  }
  if (Object.keys(value).length === 0 && value.constructor === Object) {
    return true
  }
  if (Array.isArray(value) && value.length === 0) {
    return true
  }
  return false
}

function invalidAddress(value) {
  if (!value || Object.keys(value).length === 0) return false
  const { street, hasLift } = value
  return !street || hasLift === undefined
}

function lessThanMin(value, minValue) {
  return value < minValue
}

function lessThanMinLength(value, minValue) {
  return value.length < minValue
}

function moreThanMaxLength(value, maxValue) {
  return value.length > maxValue
}

function notEqual(value, value2) {
  return value !== value2
}

export function allowInteger(e) {
  const key = getKeyPressed(e)
  const regex = /[0-9]/
  if (!regex.test(key)) {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
  }
}

export function allowDecimal(e) {
  const key = getKeyPressed(e)
  const regex = /[0-9]|\./
  if (!regex.test(key)) {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
  }
}

function getKeyPressed(e) {
  if (e.type === 'paste') {
    let key = e.clipboardData.getData('text/plain')
    if (!key) key = e.clipboardData.getData('text')
    return key
  }
  return String.fromCharCode(e.keyCode || e.which)
}

function getLabelKey(id, label, moduleName) {
  return label || (moduleName ? `${moduleName}.field.${id}` : `field.${id}`)
}

function isRequired(id, validations) {
  if (!validations) return false

  const validation = validations[id]
  if (!validation) return false

  return validation.some((item) => item.type === 'required')
}

export function renderDefinition({
  format = 'html',
  moduleName,
  state,
  id,
  valKey,
  value,
  label,
  show,
  href,
  target = '_self',
}) {
  const labelKey = getLabelKey(id, label, moduleName)
  const val = value === undefined ? state[valKey || id] : value

  if (format === 'print') {
    return { id, label: labelKey, value: val }
  }
  const isShow = show === undefined ? !!state.id : show
  if (href) {
    return (
      <Definition show={isShow} label={labelKey}>
        <Link mt={1} href={href} target={target}>
          {val}
        </Link>
      </Definition>
    )
  }

  return <Definition show={isShow} label={labelKey} value={val} />
}

export function renderTextInput({
  show = true,
  format = 'html',
  profile = 'view',
  state,
  setState,
  validation,
  moduleName,
  id,
  valKey,
  label,
  placeholder,
  href,
  target,
}) {
  if (!show) return null

  if (profile === 'view') {
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      label,
      valKey,
      show: true,
      href,
      target,
    })
  }

  return (
    <TextInput
      id={id}
      label={getLabelKey(id, label, moduleName)}
      placeholder={placeholder}
      required={isRequired(id, validation)}
      value={state[id]}
      onChange={handleTextChange(id, state, setState, validation)}
      errMsg={state.__error__[id]}
    />
  )
}

export function renderNumberInput({
  format = 'html',
  profile = 'view',
  state,
  setState,
  validation,
  moduleName,
  id,
  valKey,
  label,
  max,
  min,
}) {
  if (profile === 'view') {
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      label,
      valKey,
      show: true,
    })
  }

  return (
    <NumberInput
      id={id}
      label={getLabelKey(id, label, moduleName)}
      required={isRequired(id, validation)}
      min={min}
      max={max}
      value={state[id]}
      onChange={handleTextChange(id, state, setState, validation)}
      errMsg={state.__error__[id]}
    />
  )
}

export function renderDateInput({
  format = 'html',
  profile = 'view',
  state,
  setState,
  validation,
  moduleName,
  id,
  valKey,
  label,
  role,
  min,
  max,
}) {
  if (profile === 'view') {
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      valKey,
      label,
      show: true,
    })
  }

  return (
    <DateInput
      id={id}
      label={getLabelKey(id, label, moduleName)}
      required={isRequired(id, validation)}
      value={state[id]}
      role={role}
      min={min}
      max={max}
      onChange={handleTextChange(id, state, setState, validation)}
      errMsg={state.__error__[id]}
    />
  )
}

export function renderPhoneInput({
  format = 'html',
  profile = 'view',
  state,
  setState,
  validation,
  moduleName,
  id,
  valKey,
  label,
  type,
}) {
  if (profile === 'view') {
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      valKey,
      label,
      show: true,
    })
  }

  return (
    <PhoneInput
      id={id}
      label={getLabelKey(id, label, moduleName)}
      type={type}
      required={isRequired(id, validation)}
      value={state[id]}
      onChange={handleTextChange(id, state, setState, validation)}
      errMsg={state.__error__[id]}
    />
  )
}

export function renderTextAreaInput({
  format = 'html',
  profile = 'view',
  state,
  setState,
  validation,
  moduleName,
  id,
  valKey,
  label,
}) {
  if (profile === 'view') {
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      label,
      valKey,
      show: true,
    })
  }

  return (
    <TextArea
      id={id}
      label={getLabelKey(id, label, moduleName)}
      required={isRequired(id, validation)}
      value={state[id]}
      onChange={handleTextChange(id, state, setState, validation)}
      errMsg={state.__error__[id]}
    />
  )
}

export function renderSelectInput({
  show = true,
  format = 'html',
  profile = 'view',
  state,
  setState,
  validation,
  moduleName,
  id,
  valKey,
  label,
  options,
  isSearchable = true,
  isClearable = true,
  isMulti = false,
  disabled = false,
  callback,
}) {
  if (!show) return null

  if (profile === 'view') {
    let val = state[valKey || id]
    if (isMulti) val = val.map((item) => item).join(', ')
    if (typeof val === 'object') val = val.label || ''
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      label,
      valKey,
      value: val,
      show: true,
    })
  }

  return (
    <Select
      disabled={disabled}
      id={id}
      label={getLabelKey(id, label, moduleName)}
      required={isRequired(id, validation)}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={isMulti}
      options={options}
      value={state[id]}
      onChange={handleSelectChange(id, state, setState, validation, callback)}
      errMsg={state.__error__[id]}
    />
  )
}

export function renderSwitchInput({
  format = 'html',
  profile = 'view',
  moduleName,
  state,
  setState,
  id,
  valKey,
  label,
  direction = 'column',
  disabled,
  callback,
}) {
  const labelKey = getLabelKey(id, label, moduleName)
  const val = state[valKey || id]

  if (format === 'print') {
    return {
      id,
      label: labelKey,
      value: val ? '&#x2611;' : '&#x2610;',
      labelClass: 'label-checkbox',
    }
  }

  if (profile === 'view') {
    return (
      <Definition
        justifyContent="space-between"
        label={labelKey}
        direction={direction}
      >
        {val ? (
          <RiCheckboxLine size="20px" />
        ) : (
          <RiCheckboxBlankLine size="20px" />
        )}
      </Definition>
    )
  }

  return (
    <Definition direction={direction} label={labelKey} labelProps={{ flex: 1 }}>
      <Switch
        disabled={disabled}
        checked={state[id]}
        onClick={async () => {
          const newVal = !state[id]
          let callbackVal = {}
          if (callback) callbackVal = await callback(newVal)
          setState({ ...state, ...callbackVal, [id]: newVal })
        }}
      />
    </Definition>
  )
}

export function renderAddressInput({
  format = 'html',
  profile = 'view',
  moduleName,
  state,
  setState,
  validation,
  id,
  valKey,
  label,
  message,
}) {
  if (profile === 'view') {
    return renderDefinition({
      format,
      state,
      moduleName,
      id,
      label,
      valKey,
      value: showAddress(state[valKey || id], message),
      show: true,
    })
  }

  const labelKey = getLabelKey(id, label, moduleName)
  return (
    <Suspense fallback={<div> Loading... </div>}>
      <Address
        id={id}
        label={labelKey}
        placeholder={labelKey}
        required={isRequired(id, validation)}
        value={state[id]}
        onChange={handleTextChange(id, state, setState, validation)}
        errMsg={state.__error__[id]}
      />
    </Suspense>
  )
}

export function renderTable({
  format = 'html',
  profile = 'view',
  state,
  id,
  columns,
  ...rest
}) {
  const rows = state[id]

  if (format === 'print') {
    return { id, columns, rows, ...rest }
  }

  return <Table profile={profile} columns={columns} rows={rows} {...rest} />
}
