import cx from 'classnames'
import Message from 'components/Message/Message'
import style from './Tooltip.module.css'

const Tooltip = ({
  className = {},
  label,
  text,
  position = 'top',
  children,
  ...props
}) => {
  if (!label && !text) return null

  return (
    <div className={cx(style.tooltip, className.tooltip)} {...props}>
      {children}
      <span
        className={cx(
          style['tooltip__text'],
          style[`tooltip__text--${position}`],
          className.text,
        )}
      >
        {text ? text : <Message id={label} />}
      </span>
    </div>
  )
}

export default Tooltip
