const user = {
  'module.user': '用戶',
  'user.activityNotFound': '用戶未參與活動',
  'user.notFound': '用戶不存在',
  'user.title.list': '用戶目錄',
  'user.title.add': '新增用戶',
  'user.title.edit': '修改用戶',
  'user.title.view': '用戶詳情',
  'user.title.import': '匯入用戶備註',
  'user.section.activity': '活動目錄',
  'user.field.id': '用戶ID',
  'user.field.username': 'Email',
  'user.field.name': '姓名',
  'user.field.password': '密碼',
  'user.field.govId': '身份證字號',
  'user.field.cellphone': '手機號碼',
  'user.field.address': '聯絡地址',
  'user.field.uploadImage': '上傳圖片',
  'user.field.activityName': '活動名稱',
  'user.field.activityContent': '活動資訊',
  'user.activityTitle.edit': '修改活動資訊',
  'user.status.ACTIVE': '已激活',
  'user.status.PENDING': '未激活',
  'user.status.INACTIVE': '停用',
  'userMemo.title.import': '匯入備註',
  'userMemo.message.template': '用戶備註匯入範本',
  'userMemo.field.importFile': '匯入文件',
}

export default user
